a,
a:hover {
    text-decoration: none;
}

.App {
    padding-bottom: 60px;    
}

.App-content {
    margin-top: 1em;
}

footer {
    font-size: 0.8em;
    /* text-align: center; */
    color: #EEE;
}

.pokesquire-title {
    font-size: 24px;
    line-height: 1.25;

    img {
        margin-right: 0.5em;
    }
}

@media (max-width: 768px) {
    footer span {
        display: block;
        width: 100%;
        text-align: center;
    }
}