body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.red {
    color: #F33;
}

.white {
    color: #FFF;
}
a,
a:hover {
    text-decoration: none;
}

.App {
    padding-bottom: 60px;    
}

.App-content {
    margin-top: 1em;
}

footer {
    font-size: 0.8em;
    /* text-align: center; */
    color: #EEE;
}

.pokesquire-title {
    font-size: 24px;
    line-height: 1.25;

    img {
        margin-right: 0.5em;
    }
}

@media (max-width: 768px) {
    footer span {
        display: block;
        width: 100%;
        text-align: center;
    }
}
.home {
    margin-top: 1em;
}
.type_link,
.type_link:hover,
.type_link:active,
.type_link:visited {
    display: block;
    width: 100%;
    padding: 1em;
    margin: 1em 0;
    text-align: center; 
    text-transform: capitalize;
    font-weight: bold;   
    border-radius: 0.5em;
    color: #FFF;
}

/* white text */
.fire { background-color: #fd7d24 }
.water { background-color: #4592c4 }
.grass { background-color: #9bcc50 }
.ice { background-color: #51c4e7 }
.fighting { background-color: #d56723 }
.poison { background-color: #b97fc9 }
.psychic { background-color: #f366b9 }
.bug { background-color: #729f3f }
.rock { background-color: #a38c21 }
.ghost { background-color: #7b62a3 }
.steel { background-color: #9eb7b8 }

.fire,
.water,
.grass,
.ice,
.fighting,
.poison,
.psychic,
.bug,
.rock,
.ghost,
.dragon,
.steel { 
    color: #FFF;
 }

/* white with gradient background */
.dragon {
    background: -webkit-linear-gradient(top, #53a4cf 50%, #f16e57 50%);
    background: linear-gradient(180deg, #53a4cf 50%, #f16e57 50%);
    background-color: #53a4cf;
}

/* black text */
.normal { 
    background-color: #a4acaf;
}
.electric { 
    background-color: #eed535;
}
.fairy {
    background-color: #fdb9e9;
}

.type_link.normal,
.type_link:hover.normal,
.type_link:active.normal,
.type_link:visited.normal,
.type_link.electric,
.type_link:hover.electric,
.type_link:active.electric,
.type_link:visited.electric,
.type_link.ground,
.type_link:hover.ground,
.type_link:active.ground,
.type_link:visited.ground,
.type_link.flying,
.type_link:hover.flying,
.type_link:active.flying,
.type_link:visited.flying,
.type_link.fairy,
.type_link:hover.fairy,
.type_link:active.fairy,
.type_link:visited.fairy {
    color: #333;
}

/* black with gradient background */
.ground { 
    background: -webkit-linear-gradient(top, #f7de3f 50%, #ab9842 50%); 
    background: linear-gradient(180deg, #f7de3f 50%, #ab9842 50%);
    background-color: #f7de3f;
}
.flying { 
    background: -webkit-linear-gradient(top, #3dc7ef 50%, #bdb9b8 50%); 
    background: linear-gradient(180deg, #3dc7ef 50%, #bdb9b8 50%);
    background-color: #3dc7ef; 
}

.breadcrumb-item {
    text-transform: capitalize;
}

.pokemon_link,
.pokemon_link:hover,
.pokemon_link:active,
.pokemon_link:visited {
    display: block;
    width: 100%;
    padding: 1em;
    margin: 1em 0;
    color: #333;
    font-weight: bold;
    text-transform: capitalize;
    border: thin solid #CCC;
    border-radius: 0.5em;
}

.pokemon_link .badge {
    margin-left: 0.5em;
    line-height: 1.5;
}

.pokemon_number {
    margin-right: 0.5em;
    margin-left: 0;
}
h3 {
    line-height: 1.5em;
    text-transform: capitalize;
    overflow: auto;
}

h3 .badge {
    line-height: 1.5;
}

.type-badge {
    margin-left: 0.5em;
}

.pokemon_number {
    margin-right: 0.5em;
}

h4 {
    text-transform: capitalize;
    text-align: center;
    border-radius: 0.25em;
    line-height: 1.5em;
}



.pokemon_damage_table {
    margin-top: 1em;
}

.notamon {
    max-width: 100%;
}

