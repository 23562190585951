h3 {
    line-height: 1.5em;
    text-transform: capitalize;
    overflow: auto;
}

h3 .badge {
    line-height: 1.5;
}

.type-badge {
    margin-left: 0.5em;
}

.pokemon_number {
    margin-right: 0.5em;
}

h4 {
    text-transform: capitalize;
    text-align: center;
    border-radius: 0.25em;
    line-height: 1.5em;
}



.pokemon_damage_table {
    margin-top: 1em;
}
