.pokemon_link,
.pokemon_link:hover,
.pokemon_link:active,
.pokemon_link:visited {
    display: block;
    width: 100%;
    padding: 1em;
    margin: 1em 0;
    color: #333;
    font-weight: bold;
    text-transform: capitalize;
    border: thin solid #CCC;
    border-radius: 0.5em;
}

.pokemon_link .badge {
    margin-left: 0.5em;
    line-height: 1.5;
}

.pokemon_number {
    margin-right: 0.5em;
    margin-left: 0;
}